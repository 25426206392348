import type { FC } from 'react'
import { VERSION } from '@/constants'

const linkCn = 'font-medium text-gray-600 hover:text-primary mx-3'

export const Footer: FC<{}> = () => {
  const currentYear = new Date().getFullYear()
  
  return (
    <footer className="bg-gray-100 w-full py-5 px-4">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex flex-wrap items-center">
          <span className="text-sm text-gray-600 mx-3">© {currentYear} Appfluence Inc</span>
          
          <a
            className={linkCn}
            href="https://prioritymatrix.com/?utm_campaign=teams-reminder"
            target="_blank"
            rel="noreferrer"
          >
            Priority Matrix
          </a>
          
          <a
            className={linkCn}
            href="https://delayedsend.com/?utm_campaign=teams-reminder"
            target="_blank"
            rel="noreferrer"
          >
            DelayedSend
          </a>
        </div>
        
        <span className="text-xs text-gray-500 mx-3 mt-2 md:mt-0">Version {VERSION}</span>
      </div>
    </footer>
  )
}
